import { ReactElement, useState } from 'react';
import { Grant, FetchReturn, RootState } from '../../store';
import { CabModal } from '@CabComponents/CabModal';
import { CabButton } from '@CabComponents/CabButton';
import { useSelector } from 'react-redux';
import { cabCaptureMessage } from '../../utils/logging';
import { Box } from '@mui/material';

interface ComponentProps {
  
  grant?: Grant;
  signIn: () => Promise<FetchReturn<Record<string, unknown>, {scopes: string[]}>>
  onOAuthGranted: (providerId: number) => void;
  children: (value: {onClick: () => void}) => ReactElement
  PopUpContent: () => ReactElement
  provider: {id: number, name: string, label: string}
}

type Props = ComponentProps;

const OAuthButton = ({
  signIn, onOAuthGranted, children, PopUpContent, provider
}: Props): ReactElement => {
  const [missingScopes, setMissingScopes] = useState<string[]>([]);

  const user = useSelector((state: RootState) => state.auth.user);

  const onClick = async (): Promise<void> => {
    const response = await signIn();
    if (response.status === 200) {
      onOAuthGranted(1);
    } else if (response.status === 400) {
      switch (response.data.code) {
        case 'missing_scopes':
          try {
            cabCaptureMessage(
              `Missing Scopes Detected on OAuth Login Attempt with ${provider.label} User: (${user?.id})`
            );
          } finally {
            setMissingScopes(response.data.detail.scopes);
          } 
      }
    }
  };

  const handleRetry = () => {
    setMissingScopes([]);
    onClick();
  };

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {children({onClick})}
      <CabModal
        open={missingScopes.length > 0}
        title="We're missing a few permissions"
        actionButtons={<>
          <CabButton onClick={handleRetry}>Try Again</CabButton>
          <CabButton buttonType="tertiary" onClick={() => setMissingScopes([])}>Cancel</CabButton>
        </>}>
        <PopUpContent />
      </CabModal>
    </Box>
  );
};

export default OAuthButton;
